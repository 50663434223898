@import "../../partials/variables";
@import "../../partials/media-queries";
@import "../../partials/classes";

// Mobile First Design

.order-information {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #492897;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.color-green {
  color: #0f8560;
}
.color-red {
  color: #bc0000;
}

.view-tracking-details .p-accordion-header {
  display: none;
}
.view-tracking-details .p-accordion-content {
  border: unset;
  max-height: 280px;
  overflow: scroll;
  margin-top: 20px;
  border-top: 1px solid #000;
}

.vertical-line {
  border-left: 1px solid #492897;
  height: 72px;
}

.status-details {
  font-size: 10px;
  color: #000;
}

.track-info-box {
  margin: 0px 12px;
  border-bottom: 1px solid #e5e5e5;
}

.availability-soon {
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: #e5e5e5;
  margin: 0px;
}

.tracking-info-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 6px;
  padding-top: 8px;
}
.tracking-info-subheading {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding-bottom: 8px;
}

.map-content-height {
  height: 300px;
  border-radius: 20px;
  overflow: "hidden";
}

.refresh-rider-location-button {
  font-size: 12px;
  color: #492897 !important;
  background: #e4dcf3 !important;
  border-radius: 4px;
  border: unset;
  bottom: 46px;
  left: 12px;
  font-weight: 600;
}

.refresh-rider-location-disabled {
  font-size: 12px;
  color: #666666 !important;
  background: #e5e5e5 !important;
  border-radius: 4px;
  border: unset;
  bottom: 46px;
  left: 12px;
  font-weight: 600;
}

.availability-container-content {
  background: #ffffff;
  box-shadow: 0px 2.93394px 5.86788px rgba(196, 196, 196, 0.3);
  border-radius: 12px;
  padding: 16px 12px;
  width: 100%;
  margin-top: 12px;
}

.brand-heading {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin: 0px;
}
.brand-name-information {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin: 0px;
  text-transform: capitalize;
}

.timezone-info {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-top {
  top: 85%;
}

.leaflet-left {
  left: 95%;
}

.leaflet-div-icon{
background-color: transparent;
border: none;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.delay-notification {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: rgb(255, 234, 236);
  box-shadow: rgba(196, 196, 196, 0.3) 0px 2px 4px 0px;
  margin-top: 12px;
  img	{
    background: rgb(188, 0, 0);
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
}
// Media Queries
@media #{$tablet-to-infinity} {
  .availability-container-content {
    background: #ffffff;
    box-shadow: 0px 2.93394px 5.86788px rgba(196, 196, 196, 0.3);
    border-radius: 12px;
    padding: 24px 16px;
    width: 100%;
    margin-top: 0;
  }
  .order-information {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    color: #492897;
    margin-top: 0px;
    text-transform: capitalize;
    margin-bottom: 0px;
  }
  .availability-soon {
    font-weight: 400;
    font-size: 34px;
    line-height: 60px;
    color: #e5e5e5;
    margin: 0px;
  }
  .status-details {
    font-size: 12px;
    color: #000;
  }
  .view-tracking-details .p-accordion-header {
    display: none;
  }
  .view-tracking-details .p-accordion-content {
    border: unset;
    max-height: 280px;
    overflow: scroll;
    margin-top: 20px;
    border-top: 1px solid #000;
  }
  .tracking-info-heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 6px;
    padding-top: 8px;
  }
  .tracking-info-subheading {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #707070;
    padding-bottom: 8px;
  }
  .track-info-box {
    margin: 0px 12px;
    border-bottom: 1px solid #e5e5e5;
  }
  .vertical-line {
    border-left: 1px solid #492897;
    height: 50px;
  }
  .timezone-info {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .map-content-height {
    height: 100%;
    border-radius: 20px;
    overflow: "hidden";
  }
  .tracking-detail-box {
    position: absolute;
    top: 8px;
    left: -3px;
    padding-left: 12px;
    padding-right: 12px;
    z-index: 1000;
  }
  .refresh-rider-location-button {
    font-size: 14px;
    color: #492897 !important;
    background: #e4dcf3 !important;
    border-radius: 4px;
    border: unset;
    bottom: 46px;
    left: 12px;
    font-weight: 600;
  }
  .color-green {
    color: #0f8560;
  }
  .color-red {
    color: #bc0000;
  }
  .brand-heading {
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0px;
  }
  .brand-name-information {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
    margin: 0px;
    margin-bottom: 28px;
    text-transform: capitalize;
  }
}
