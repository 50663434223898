@import "../../partials/variables";
@import "../../partials/classes";
@import "../../partials/media-queries";

.track-section-alignment {
  flex-direction: column-reverse;
}

.powered-pidge {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 0px;
  color: #000000 !important;
  display: block;
  text-align: right;
}

.powered-info-margin {
  margin: 0px;
}
.top-bar {
  width: 100%;
  background-color: "red";
  text-align: center;
  padding: 5px 0;
}

// Media Queries
@media #{$tablet-to-infinity} {
  .powered-pidge {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 8px;
    display: flex;
    color: #000000 !important;
  }
  .track-section-alignment {
    flex-direction: unset;
  }

  .powered-info-margin {
    margin-right: 4px;
  }
}
