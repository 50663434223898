@import "../../../partials/variables";
@import "../../../partials/classes";
@import "../../../partials/media-queries";

.login-page {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__card {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 86%;
	}

	&__card-title {
		margin: 0 0 0.25rem 0;
	}

	&__card-subtitle {
		font-size: 0.75rem;
		margin: 0 0 2rem 0;
		font-weight: 400;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.p-input-icon-right {
			margin-bottom: 1rem;
			width: 100%;
		}

		input {
			padding: 0.33rem 0.75rem;
			width: 100%;
			border-radius: $br-5;
			border-color: $color-primary-light;
			color: $color-primary;
			font-weight: 600;

			&:first-child {
				margin-bottom: 0.5rem;
			}
		}

		.submit-btn {
			margin-top: 1rem;
			min-width: 40%;

		}
	}
}

// Media Queries
@media #{$tablet-to-infinity} {
	.login-page {
		&__card {
			width: 50%;
		}
	}
}
