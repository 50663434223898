/* Color Palette */
$color-primary: #492897;
$color-primary-light: rgba(73, 40, 151, 0.24);
$color-primary-lightest: rgba(73, 40, 151, 0.1);
$color-black: #000000;
$color-secondary: #ffffff;
$color-bg-muted: #D8D8D8;
$color-text-muted: rgba(0, 0, 0, 0.45);

/* Border Radius */
$br-1: 2px;
$br-2: 4px;
$br-3: 8px;
$br-4: 16px;
$br-5: 20px;

/* Box Shadows */
$bxs-1: 10px 10px 44px rgba(73, 40, 151, 0.1);
