body {
  margin: 0;
  font-family: "HK Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
  display: flex;
}

.justify-content-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.space-between-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.space-evenly-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.margin-unset {
  margin: 0px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-right-8 {
  padding-right: 8px;
}

.padding-top-8 {
  padding-top: 8px;
}

.cursor-pointer {
  cursor: pointer;
}
.m-auto {
  margin: auto;
}
.flex-wrap {
  flex-wrap: wrap;
}

.margin-right-none {
  margin-right: 0px !important;
}

.cursor-unset {
  cursor: unset !important;
}

p{
  margin-top: 0px;
  margin-bottom: 0px;
}