@import "./partials/media-queries";

.content {
  margin: 0rem 2rem 0 2rem;
}

@font-face {
  font-family: "HK Grotesk Bold";
  src: local("HK Grotesk"),
    url(./assets/fonts/HKGrotesk-Bold.otf) format("opentype");
}
@font-face {
  font-family: "HK Grotesk SemiBold";
  src: local("HK Grotesk"),
    url(./assets/fonts/HKGrotesk-SemiBold.otf) format("opentype");
}
@font-face {
  font-family: "HK Grotesk Medium";
  src: local("HK Grotesk"),
    url(./assets/fonts/HKGrotesk-Medium.otf) format("opentype");
}
@font-face {
  font-family: "HK Grotesk Regular";
  src: local("HK Grotesk"),
    url(./assets/fonts/HKGrotesk-Regular.otf) format("opentype");
}
@font-face {
  font-family: "HK Grotesk Light";
  src: local("HK Grotesk"),
    url(./assets/fonts/HKGrotesk-Light.otf) format("opentype");
}

// Media Queries
@media #{$tablet-to-infinity} {
  .content {
    margin: 0rem 6rem 0 6rem;
  }
}
