@import "../../partials/variables";
@import "../../partials/media-queries";

.stateful-ui {
	display: flex;
	color: $color-text-muted;

	&.loading, &.empty, &.failure {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1 1 auto;

		&.loading{
			img	{
				width: 5rem;
			}
		}
	}

	&__state-label {
		margin-top: 0.75rem;
		font-size: 1.25rem;
  }
  
  .stateful-ui__state-label-error {
    color: red;
  }
}
@media #{$small-mobile-to-tablet} {
	.image {
		content: url('../../assets//img/error_image_mobile.svg');
		width: 100%;
	}

	.image_empty{
		content: url('../../assets//img/empty_state_mobile.svg');
		width: 100%;
	}
  }
