@import "./variables";

/* Border Radiuses */

.br-1 {
	border-radius: 2px;
}

.br-2 {
	border-radius: 4px;
}

.br-3 {
	border-radius: 8px;
}

.br-4 {
	border-radius: 16px;
}

/* Buttons */

.pidge-btn {
	border-radius: $br-4;
	font-weight: 600;
	padding: 0.5rem;

	&.primary {
		background-color: $color-primary;
		color: $color-secondary;
		filter: drop-shadow(0px 0px 16px rgba(73, 40, 151, 0.416));
	}

	&.muted {
		background-color: $color-bg-muted;
		color: $color-black;
	}
}

.auth-card {
	border-radius: $br-4;
	padding: 2rem;
	box-shadow: 4px 4px 10px rgba(73, 40, 151, 0.129);
}

/* Colors */

.color-primary {
	color: $color-primary;
}

/* Buttons */
.pidge-btn {
	font-weight: 600 !important;
	padding: 0.5rem 1rem !important;
	box-shadow: 0 0 10px rgba(73, 40, 151, 0.596) !important;
	border : unset;
	color: #ffff !important;
	background-color: $color-primary !important;
}

.pidge-btn-without-shadow {
	font-weight: 600 !important;
	padding: 0.5rem 1rem !important;
	box-shadow: unset !important;
	background-color: $color-primary !important;
	border: unset
}

.pidge-btn-disable:disabled {
	background: #E5E5E5 !important;
	border: 2px solid #E5E5E5 !important;
	opacity: unset !important;
	color: #666666;
	font-size: 14px;
	background: unset;
	box-shadow: unset !important;
}

.pidge-btn-secondary {
	font-weight: 600 !important;
	padding: 0.5rem 1rem !important;
	color: $color-primary;
	box-shadow: $color-secondary  !important;
	border: unset;
	background-color: $color-secondary !important;
}
