@import "../../partials/variables";
@import "../../partials/media-queries";

// Mobile First Design
.pidge-navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2rem;
	border-bottom-left-radius: $br-4;
	border-bottom-right-radius: $br-4;
  	box-shadow: 0 4px 13px rgba(180, 180, 180, 0.161);

	&__brand-logo {
		display: flex;
		flex-direction: column;
		justify-content: center;

		img {
			width: 4rem;
		}
	}

	&__link {
		margin-right: 1rem;
		text-decoration: none !important;
		color: black;
		font-size: 0.875rem;
		font-weight: 800;

		svg	{
			margin-left: 0.5rem;
		}
	}
}

// Media Queries
@media #{$tablet-to-infinity} {
	.pidge-navbar {
		border-radius: 0 !important;
		box-shadow: none !important;
		padding: 1rem 6rem;

		&__links {
			display: flex;
		}

		&__link {
			margin-right: 2rem;
		}
	}
}
