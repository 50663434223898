@import "../../partials/variables";
@import "../../partials/media-queries";
@import "../../partials/classes";

// Mobile First Design

.order-information {
  font-weight: 700;
  font-size: 50px;
  line-height: 56px;
  color: #492897;
  margin-top: 28px;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.order-status {
  font-size: 12px;
  color: #000;
}
.delivery-information-box {
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 2.93394px 5.86788px rgba(196, 196, 196, 0.3);
  border-radius: 12px;
  height: 100%;
  margin-top: 16;
  flex: 1;
  margin-bottom: 16px;
}
.delivery-tracking-info {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  padding-bottom: 8px;
  border-bottom: 1.46697px solid #e5e5e5;
}
.information {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.location {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #492897;
}
.payment-status {
  color: #0f8560;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  background: #c7dfd8;
  border-radius: 34px;
  padding: 4px 12px;
}
.order-info {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.secure-delivery-info {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #8d027d;
  margin-top: 12px;
}
.feedback-infomation {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
}
.feedback-activation-section {
  background: #f3eff9;
  border-radius: 4px;
  color: #9980db;
  padding: 26px 12px;
  margin-top: 8px;
}
.activate-soon {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 24px;
}
.feedback-subheading {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
.label-information {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding-left: 4px;
}
.order-information-content .p-tabview .p-tabview-nav {
  // border: unset;
  text-align: center;
}
.emogis-highlight-background {
  padding: 12px;
  background: #f4f2f8;
  border-radius: 6px;
}
.emogis-highlight-blur {
  padding: 12px;
  background: #fff;
  opacity: 0.6;
  border-radius: 6px;
}
.emogis-feedback-exist {
  padding: 12px;
  background: #fff;
  opacity: 0.6;
  border-radius: 6px;
}
.emogis-highlight-blur:hover {
  padding: 12px;
  background: #f4f2f8;
  opacity: 1;
  border-radius: 6px;
}
.emogies-feedback-information {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #492897;
}
.emogies-feedback-subheading {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #000;
}

.submitted-container {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 8px 12px;
  color: #666666;
  background: #fafafa;
  min-height: 120px;
}
.request-callback-box {
  background: #c4c4c4;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.submitted-feedback-button {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #0f8560;
}

.otp-info-heading {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-top: 4px;
}

.otp-info-subheading {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #666666;
  margin-top: 12px;
}

.active-soon-container {
  background: #f3eff9;
  border-radius: 4px;
  padding: "58px 98px";
}
.order-detail-wrapper {
  display: flex;
  flex-direction: column;
}
.slider-wrap {
  width: "100%";
  height: 150;
}

.product-detail-wrap {
  box-shadow: 0px 2px 4px 0px rgba(196, 196, 196, 0.30);
  border-top-left-radius: 12px;
  border-top-right-radius:  12px;
  padding: 16px;
  margin-bottom: 12px,
}

.product-item{
  flex: 100%;
  height: 56px;
  padding: 12px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
}

// Media Queries
@media #{$tablet-to-infinity} {
  .order-information {
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: #492897;
    margin-top: 28px;
    margin-bottom: 0px;
    text-transform: capitalize;
  }
  .submitted-container {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 12px;
    color: #666666;
    background: #fafafa;
    min-height: 120px;
  }

  .request-callback-box {
    background: #c4c4c4;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .submitted-feedback-button {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #0f8560;
  }

  .order-status {
    font-size: 12px;
    color: #000;
  }
  .delivery-information-box {
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 2.93394px 5.86788px rgba(196, 196, 196, 0.3);
    border-radius: 12px;
    margin-top: 24;
    height: 100%;
  }
  .order-detail-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
  }
  .slider-wrap {
    width: 250;
    height: 150;
  }
  .delivery-tracking-info {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #666666;
    padding-bottom: 8px;
    border-bottom: 1.46697px solid #e5e5e5;
  }
  .information {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  .location {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #492897;
    display: flex;
  }
  .payment-status {
    color: #0f8560;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    background: #c7dfd8;
    border-radius: 34px;
    padding: 4px 12px;
  }
  .order-info {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
  .secure-delivery-info {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #8d027d;
    margin-top: 12px;
  }
  .feedback-infomation {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
  }
  .feedback-activation-section {
    background: #f3eff9;
    border-radius: 4px;
    color: #9980db;
    padding: 26px 12px;
    margin-top: 8px;
  }
  .activate-soon {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 24px;
  }
  .feedback-subheading {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
  .label-information {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    padding-left: 4px;
  }
  .order-information-content .p-tabview .p-tabview-nav {
    // border: unset;
    text-align: center;
  }
  .emogis-highlight-background {
    padding: 12px;
    background: #f4f2f8;
    border-radius: 6px;
  }
  .emogis-highlight-blur {
    padding: 12px;
    background: #fff;
    border-radius: 6px;
    opacity: 0.6;
  }
  .emogis-highlight-blur:hover {
    padding: 12px;
    background: #f4f2f8;
    opacity: 1;
    border-radius: 6px;
  }
  .emogis-feedback-exist {
    padding: 12px;
    background: #fff;
    opacity: 0.6;
    border-radius: 6px;
  }
  .emogies-feedback-information {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #492897;
  }
  .emogies-feedback-subheading {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000;
  }
  .otp-info-heading {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }
  .otp-info-subheading {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #666666;
    margin-top: 12px;
  }
  .active-soon-container {
    background: #f3eff9;
    border-radius: 4px;
    padding: 58px 98px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #9980db;
  }
  .feedback-subinformation-margin {
    margin-top: 24px;
  }
  .product-item{
    flex: 48%;
  }
}
